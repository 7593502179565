import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, TextField, Divider, CardActions, Button } from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab';
import differenceInYears from 'date-fns/differenceInYears';
import { makeStyles } from '@material-ui/core/styles';
import useBreakpoints from '../styles/useBreakpoints';

import departamentos from '../data/departamentos';
import municipios from '../data/municipios';

const useStyles = makeStyles((theme) => ({
    backgroundAutocomplete: {
        backgroundColor: '#E0E0E0'
    }
}));

function Datos(props) {
    const classes = useStyles();
    const { theme, validar } = props;
    const [errores, setErrores] = props.errores;
    const point = useBreakpoints();
    const [primerNombre, setPrimerNombre] = useState('');
    const [segundoNombre, setSegundoNombre] = useState('');
    const [primerApellido, setPrimerApellido] = useState('');
    const [segundoApellido, setSegundoApellido] = useState('');
    const [cedula, setCedula] = useState('');
    const [cedula2, setCedula2] = useState('');
    const [nacimiento, setNacimiento] = useState(null);
    const [genero, setGenero] = useState(null);
    const [telefono, setTelefono] = useState('');
    const [telefono2, setTelefono2] = useState('');
    const [correo, setCorreo] = useState('');
    const [departamento, setDepartamento] = useState(null);
    const [municipio, setMunicipio] = useState(null);
    const [direccion, setDireccion] = useState('');
    const [tipo, setTipo] = useState(null);
    const [regional, setRegional] = useState(null);
    const [afiliacion, setAfiliacion] = useState(null);
    const [otraAfiliacion, setOtraAfiliacion] = useState('');

    const limpiar = () => {
        setPrimerNombre('');
        setSegundoNombre('');
        setPrimerApellido('');
        setSegundoApellido('');
        setCedula('');
        setCedula2('');
        setNacimiento(null);
        setGenero(null);
        setTelefono('');
        setTelefono2('');
        setCorreo('');
        setDepartamento(null);
        setMunicipio(null);
        setDireccion('');
        setTipo(null);
        setRegional(null);
        setAfiliacion(null);
        setOtraAfiliacion('')
        setErrores(Array(16).fill(false));
    }

    return (
        <Card>
            <CardContent>
                <Typography variant='body2'>(*) Campos obligatorios. <span style={{ color: '#e53935' }}>(Para no tener errores en los pagos por favor digitar datos como aparecen en la cédula).</span></Typography>
                <Typography variant='body2'>(**) Daviplata. <span style={{ color: '#e53935' }}>(En caso de que el pago se vaya a realizar por ese medio).</span></Typography>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'>*Primer nombre:</Typography>
                        <TextField
                            value={primerNombre}
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase().replaceAll('Ñ', '0') + e.target.value.slice(1).toLowerCase().replaceAll('ñ', '1') }; setPrimerNombre(value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll('0', 'Ñ').replaceAll('1', 'ñ').replace(/[^a-zA-ZÑñ]/g, '')); errores[0] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            autoFocus
                            error={errores[0]}
                            helperText={errores[0] ? 'El primer nombre no puede quedar vacío' : null}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Segundo nombre (si no tiene deje este campo en blanco):</Typography>
                        <TextField
                            value={segundoNombre}
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { if (value.includes(' ')) { let values = e.target.value.split(' '); value = ''; for (let i = 0; i < values.length; i++) { value = values[i] === '' ? value + ' ' : value + values[i][0].toUpperCase().replaceAll('Ñ', '0') + values[i].slice(1).toLowerCase().replaceAll('ñ', '1') + ' ' } value = value.slice(0, -1) } else { value = e.target.value[0].toUpperCase().replaceAll('Ñ', '0') + e.target.value.slice(1).toLowerCase().replaceAll('ñ', '1') } }; setSegundoNombre(value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll('0', 'Ñ').replaceAll('1', 'ñ').replace(/[^a-zA-ZÑñ ]/g, '')); }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            style={{ marginBottom: errores[0] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'>*Primer apellido:</Typography>
                        <TextField
                            value={primerApellido}
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase().replaceAll('Ñ', '0') + e.target.value.slice(1).toLowerCase().replaceAll('ñ', '1') }; setPrimerApellido(value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll('0', 'Ñ').replaceAll('1', 'ñ').replace(/[^a-zA-ZÑñ]/g, '')); errores[1] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[1]}
                            helperText={errores[1] ? 'El primer apellido no puede quedar vacío' : null}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Segundo apellido (si no tiene deje este campo en blanco):</Typography>
                        <TextField
                            value={segundoApellido}
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { if (value.includes(' ')) { let values = e.target.value.split(' '); value = ''; for (let i = 0; i < values.length; i++) { value = values[i] === '' ? value + ' ' : value + values[i][0].toUpperCase().replaceAll('Ñ', '0') + values[i].slice(1).toLowerCase().replaceAll('ñ', '1') + ' ' } value = value.slice(0, -1) } else { value = e.target.value[0].toUpperCase().replaceAll('Ñ', '0') + e.target.value.slice(1).toLowerCase().replaceAll('ñ', '1') } }; setSegundoApellido(value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll('0', 'Ñ').replaceAll('1', 'ñ').replace(/[^a-zA-ZÑñ ]/g, '')); }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            style={{ marginBottom: errores[1] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Número de cédula:</Typography>
                        <TextField
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            value={cedula}
                            onChange={(e) => { if (e.target.value.length <= 10) setCedula(e.target.value.replace(/[^0-9]/g, '')); errores[2] = false; errores[3] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[2]}
                            helperText={errores[2] ? (cedula === '' && cedula2 === '' ? 'La cédula no puede quedar vacía' : 'Las cédulas no coinciden') : null}
                            style={{ marginBottom: [errores[4], errores[5]].includes(true) && !errores[2] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Confirme número de cédula:</Typography>
                        <TextField
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            value={cedula2}
                            onChange={(e) => { if (e.target.value.length <= 10) setCedula2(e.target.value.replace(/[^0-9]/g, '')); errores[3] = false; errores[2] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[3]}
                            helperText={errores[3] ? (cedula2 === '' && cedula === '' ? 'La cédula no puede quedar vacía' : 'Las cédulas no coinciden') : null}
                            style={{ marginBottom: [errores[4], errores[5]].includes(true) && !errores[3] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Fecha de nacimiento:</Typography>
                        <KeyboardDatePicker
                            clearable
                            autoOk
                            inputVariant='outlined'
                            size='small'
                            variant='inline'
                            fullWidth
                            value={nacimiento}
                            placeholder="dd/mm/yyyy"
                            onChange={date => { setNacimiento(date); errores[4] = false; setErrores([...errores]) }}
                            format="dd/MM/yyyy"
                            error={errores[4]}
                            helperText={errores[4] ? ((differenceInYears(new Date(), nacimiento) < 18) ? 'Debe ser mayor de edad' : 'La fecha no puede quedar vacía') : null}
                            style={{ marginBottom: [errores[2], errores[5]].includes(true) && !errores[4] && 'xs' !== point ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>*Género:</Typography>
                        <Autocomplete
                            value={genero}
                            onChange={(e, v) => { setGenero(v); errores[5] = false; setErrores([...errores]) }}
                            options={
                                ['Masculino', 'Femenino'].map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[5]}
                                    helperText={errores[5] ? 'El género no puede quedar vacío' : null}
                                    style={{ marginBottom: [errores[2], errores[4]].includes(true) && !errores[5] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Número de celular (**Daviplata):</Typography>
                        <TextField
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            value={telefono}
                            onChange={(e) => { if (e.target.value.length <= 10) setTelefono(e.target.value.replace(/[^0-9]/g, '')); errores[6] = false; errores[7] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[6]}
                            helperText={errores[6] ? (telefono === '' && telefono2 === '' ? 'El celular no puede quedar vacío' : 'Los celulares no coinciden') : null}
                            style={{ marginBottom: errores[8] && !errores[6] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}

                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Confirme número de celular:</Typography>
                        <TextField
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                            value={telefono2}
                            onChange={(e) => { if (e.target.value.length <= 10) setTelefono2(e.target.value.replace(/[^0-9]/g, '')); errores[7] = false; errores[6] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[7]}
                            helperText={errores[7] ? (telefono2 === '' && telefono === '' ? 'El celular no puede quedar vacío' : 'Los celulares no coinciden') : null}
                            style={{ marginBottom: errores[8] && !errores[7] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'>*Correo electrónico:</Typography>
                        <TextField
                            value={correo}
                            onChange={(e) => { setCorreo(e.target.value.toLowerCase()); errores[8] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[8]}
                            helperText={errores[8] ? (correo === '' ? 'El correo no puede quedar vacío' : 'El correo ingresado no es válido') : null}
                            style={{ marginBottom: errores[7] && !errores[8] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={4} sm={6} xs={12}>
                        <Typography variant='body1'>*Departamento de residencia:</Typography>
                        <Autocomplete
                            value={departamento}
                            onChange={(e, v) => { setDepartamento(v); setMunicipio(null); errores[9] = false; errores[10] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[9]}
                                    helperText={errores[9] ? 'El departamento no puede quedar vacío' : null}
                                    style={{ marginBottom: [errores[10], errores[11]].includes(true) && !errores[9] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Typography variant='body1'>*Municipio de residencia:</Typography>
                        <Autocomplete
                            disabled={departamento === null}
                            value={municipio}
                            onChange={(e, v) => { setMunicipio(v); errores[10] = false; setErrores([...errores]) }}
                            options={
                                (departamento !== null ? municipios.filter(m => m.departamento === departamento) : []).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        className: departamento === null ? classes.backgroundAutocomplete : null
                                    }}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[10]}
                                    helperText={errores[10] ? 'El municipio no puede quedar vacío' : null}
                                    style={{ marginBottom: [errores[9], errores[11]].includes(true) && !errores[10] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>*Dirección de residencia:</Typography>
                        <TextField
                            value={direccion}
                            onChange={(e) => { setDireccion(e.target.value); errores[11] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[11]}
                            helperText={errores[11] ? 'La dirección no puede quedar vacía' : null}
                            style={{ marginBottom: [errores[9], errores[10]].includes(true) && !errores[11] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Tipo de campo:</Typography>
                        <Autocomplete
                            value={tipo}
                            onChange={(e, v) => { setTipo(v); errores[12] = false; setErrores([...errores]) }}
                            options={
                                ['Presencial', 'Telefónico'].map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[12]}
                                    helperText={errores[12] ? 'El tipo de encuestador no puede quedar vacío' : null}
                                    style={{ marginBottom: ((errores[13] && 'xs' !== point) || ([errores[13], errores[14], errores[15]].includes(true) && 'sm' !== point)) && !errores[12] ? theme.spacing(2.8) : null }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant='body1'>*Regional:</Typography>
                        <Autocomplete
                            value={regional}
                            onChange={(e, v) => { setRegional(v); errores[13] = false; setErrores([...errores]) }}
                            options={
                                ['Bogotá D.C.', 'Bucaramanga', 'Medellín', 'Cali', 'Barranquilla'].map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[13]}
                                    helperText={errores[13] ? 'La regional no puede quedar vacía' : null}
                                    style={{ marginBottom: ((errores[12] && 'xs' !== point) || ([errores[12], errores[14], errores[15]].includes(true) && 'sm' !== point)) && !errores[13] ? theme.spacing(2.8) : null }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Typography variant='body1'>*En relación a la afiliación al sistema de seguridad, en qué régimen se encuentra:</Typography>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={1}
                        >
                            <Grid item xs={afiliacion !== 'Regimen especial' ? 12 : 5}>
                                <Autocomplete
                                    value={afiliacion}
                                    onChange={(e, v) => { setAfiliacion(v); errores[14] = false; setErrores([...errores]); setOtraAfiliacion('') }}
                                    options={
                                        ['Régimen contributivo - Afiliado como independiente', 'Régimen contributivo - Afiliado como cotizante', 'Régimen contributivo - Afiliado como beneficiario', 'Régimen subsidiado - Sisben', 'Regimen especial'].map(option => option)
                                    }
                                    noOptionsText='No hay coincidencias'
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            error={errores[14]}
                                            helperText={errores[14] ? 'La afiliacion no puede quedar vacía' : null}
                                            style={{ marginBottom: ((errores[15]) || ([errores[12], errores[13], errores[15]].includes(true) && 'sm' !== point)) && !errores[14] ? theme.spacing(2.8) : null }}
                                        />
                                    )}
                                />
                            </Grid>
                            {afiliacion === 'Regimen especial' ?
                                <Grid item xs={7}>
                                    <TextField
                                        value={otraAfiliacion}
                                        onChange={(e) => { setOtraAfiliacion(e.target.value); errores[15] = false; setErrores([...errores]) }}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[15]}
                                        placeholder='¿Cuál?'
                                        helperText={errores[15] ? 'Debe indicar cuál' : null}
                                        style={{ marginBottom: ((errores[14]) || ([errores[12], errores[13], errores[14]].includes(true) && 'sm' !== point)) && !errores[15] ? theme.spacing(2.8) : null }}
                                    />
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider style={{ marginBottom: theme.spacing(1) }} />
            <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Button
                    onClick={() => limpiar()}
                    variant='contained'
                    color='secondary'
                >
                    Limpiar
                </Button>
                <Button
                    onClick={() => validar(primerNombre, segundoNombre, primerApellido, segundoApellido, cedula, cedula2, nacimiento, genero, telefono, telefono2, correo, departamento, municipio, direccion, tipo, regional, afiliacion, otraAfiliacion)}
                    variant='contained'
                    color='primary'
                >
                    Continuar
                </Button>
            </CardActions>
        </Card>
    );
}

export default Datos;
