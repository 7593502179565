import React, { useState } from 'react';
import Logo from '../logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import differenceInYears from 'date-fns/differenceInYears';
import { Alert } from '@material-ui/lab';
import { Redirect } from "react-router-dom";

import departamentos from '../data/departamentos';
import municipios from '../data/municipios';

import Datos from './Datos';
import Disclaimer from './Disclaimer';
import Copyright from './Copyright';

const API_DEFAULT = 'https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 500
    },
    a: {
        height: 37.39,
        [theme.breakpoints.up('sm')]: {
            height: 45.89
        },
    },
    logo: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135
        },
        marginRight: theme.spacing(4)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function Registro(props) {
    const { theme } = props;
    const classes = useStyles();
    const [errores, setErrores] = useState(Array(16).fill(false));
    const [errores2, setErrores2] = useState(Array(2).fill(false));

    const [datos, setDatos] = useState([]);
    const [continuar, setContinuar] = useState(false);

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [snack, setSnack] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const validarDatos = (primerNombre, segundoNombre, primerApellido, segundoApellido, cedula, cedula2, nacimiento, genero, telefono, telefono2, correo, departamento, municipio, direccion, tipo, regional, afiliacion, otraAfiliacion) => {
        let errorDatos = false;
        if (primerNombre === '') {
            errorDatos = true;
            errores[0] = true;
        }
        if (primerApellido === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (cedula === '' || parseInt(cedula) === 0 || cedula !== cedula2) {
            errorDatos = true;
            errores[2] = true;
        }
        if (cedula2 === '' || parseInt(cedula2) === 0 || cedula2 !== cedula) {
            errorDatos = true;
            errores[3] = true;
        }
        if (nacimiento === null || differenceInYears(new Date(), nacimiento) < 18) {
            errorDatos = true;
            errores[4] = true;
        }
        if (genero === null) {
            errorDatos = true;
            errores[5] = true;
        }
        if (telefono === '' || parseInt(telefono) === 0 || telefono !== telefono2) {
            errorDatos = true;
            errores[6] = true;
        }
        if (telefono2 === '' || parseInt(telefono2) === 0 || telefono2 !== telefono) {
            errorDatos = true;
            errores[7] = true;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(correo) === false || correo === '') {
            errorDatos = true;
            errores[8] = true;
        }
        if (departamento === null) {
            errorDatos = true;
            errores[9] = true;
        }
        if (municipio === null) {
            errorDatos = true;
            errores[10] = true;
        }
        if (direccion === '') {
            errorDatos = true;
            errores[11] = true;
        }
        if (tipo === null) {
            errorDatos = true;
            errores[12] = true;
        }
        if (regional === null) {
            errorDatos = true;
            errores[13] = true;
        }
        if (afiliacion === null) {
            errorDatos = true;
            errores[14] = true;
        }
        if (afiliacion === 'Regimen especial' && otraAfiliacion === '') {
            errorDatos = true;
            errores[15] = true;
        }

        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            setContinuar(true);
            setDatos([primerNombre, segundoNombre, primerApellido, segundoApellido, cedula, cedula2, nacimiento, genero, telefono, telefono2, correo, departamento, municipio, direccion, tipo, regional, afiliacion, otraAfiliacion]);
        }
    }

    const disclaimer = (acepta, acepta2) => {
        let errorDisclaimer = false;
        if (!acepta) {
            errorDisclaimer = true;
            errores2[0] = true;
        }
        if (!acepta2) {
            errorDisclaimer = true;
            errores2[1] = true;
        }

        if (errorDisclaimer) {
            setErrores2([...errores2]);
            setMessage('Debe aceptar las condiciones.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            putInformacion(acepta, acepta2);
        }
    }

    async function putInformacion(acepta, acepta2) {
        setShowBackdrop(true);

        let actualiza = 0;
        let res = await fetch(`${API_DEFAULT}/registro/crear_persona/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'p_nombre': datos[0],
                's_nombre': datos[1],
                'p_apellido': datos[2],
                's_apellido': datos[3],
                'cedula': parseInt(datos[4]),
                'fecha_nacimiento': datos[6].toISOString().split('T')[0],
                'genero': datos[7] === 'Masculino' ? 0 : 1,
                'celular': parseInt(datos[8]),
                'correo': datos[10],
                'departamento': departamentos.filter(d => d.departamento === datos[11])[0].dane,
                'municipio': municipios.filter(m => m.municipio === datos[12] && m.departamento === datos[11])[0].daneMuni,
                'direccion': datos[13],
                'afiliacion': datos[16] === 'Regimen especial' ? `Regimen especial - ${datos[17]}` : datos[16],
                'acepto_plagio': acepta,
                'acepto_datos': acepta2
            })
        })

        if (res.ok) {
            res.json().then(res => actualiza = res['actualiza']);
            res = await fetch(`${API_DEFAULT}/personal/crear_personal/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'persona': parseInt(datos[4]),
                    'tipo_campo': datos[14] === 'Presencial' ? 0 : 1,
                    'regional': datos[15] === 'Bogotá D.C.' ? 0 : (datos[15] === 'Bucaramanga' ? 1 : (datos[15] === 'Medellín' ? 2 : (datos[15] === 'Cali' ? 3 : 4)))
                })
            })
        }

        setShowBackdrop(false);

        if (res.ok) {
            setContinuar(false);
            setMessage(actualiza === 0 ? 'Se ha registrado exitosamente.' : 'Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            setMessage('Ocurrio un error, intente de nuevo.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    return (
        <div className='Registro'>
            <header className='Registro-header'>
                <AppBar position='static'>
                    <Toolbar>
                        <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
                            <img src={Logo} alt='logo' className={classes.logo} />
                        </a>
                        <Typography style={{ marginRight: theme.spacing(2), color: 'inherit', textDecoration: 'none' }} variant='h5' className={classes.title}>
                            Registro de datos para pagos
                        </Typography>
                    </Toolbar>
                </AppBar>
            </header>
            <div className='Registro-body' style={{ marginTop: theme.spacing(2) }}>
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                <Container component='main' maxWidth='lg'>
                    {
                        !continuar ?
                            <Datos theme={theme} errores={[errores, setErrores]} validar={validarDatos} />
                            :
                            <Disclaimer theme={theme} errores={[errores2, setErrores2]} limpiar={() => setContinuar(false)} disclaimer={disclaimer} />
                    }
                </Container>
            </div>
            <div className='Copyrighyt'>
                <Copyright />
            </div>
            <div className='Notifications'>
                <Backdrop className={classes.backdrop} open={showBackdrop}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                <Snackbar
                    open={snack}
                    autoHideDuration={6000}
                    onClose={() => setSnack(false)}
                >
                    <Alert onClose={() => setSnack(false)} severity={severity} variant='filled'>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default Registro;
