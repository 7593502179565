import React, { useState } from 'react';
import { Card, CardContent, Typography, Divider, CardActions, Button, FormControlLabel, Checkbox } from '@material-ui/core';

function Disclaimer(props) {
    const { theme, limpiar, disclaimer } = props;
    const [acepta, setAcepta] = useState(false);
    const [acepta2, setAcepta2] = useState(false);
    const [errores, setErrores] = props.errores;

    return (
        <Card>
            <CardContent>
                <Typography variant='body1' align='justify'>Yo <span style={{ fontWeight: 500 }}>{ }</span> identificado(a) con cédula de ciudadanía como aparece al lado de mi firma, en calidad de encuestador/entrevistador(a) del Centro Nacional de Consultoría (CNC), me comprometo a respetar la confidencialidad o reserva total de la información que se maneja tanto oral como escrita del estudio. Por ningún motivo divulgaré, copiaré o fotocopiaré información entregada en la capacitación como formularios y manuales, ni tampoco la información recolectada de los informantes como, por ejemplo: nombre, dirección, teléfono, empresa, contenido de la información, etc.</Typography>
                <Typography style={{ marginTop: theme.spacing(1) }} variant='body1' align='justify'>Me comprometo a garantizar la veracidad de la información, es decir, a no falsificarla, duplicarla, adulterarla o modificarla, ni tampoco entregarla a terceros diferentes a los responsables del CNC.</Typography>
                <Typography style={{ marginTop: theme.spacing(1) }} variant='body1' align='justify'>Tengo pleno conocimiento de que si el CNC, al realizar la supervisión de campo y/o crítica y codificación o revisión digital, encuentra que he cometido alguna de las faltas mencionadas anteriormente, me suspenderá definitivamente de la actividad que venía desarrollando, anulará todos los formularios que haya diligenciado y no tendré derecho a reclamar remuneración alguna por esta actividad; dependiendo de la implicación generada al cliente, este procederá jurídicamente.</Typography>
                <FormControlLabel
                    style={{ marginTop: theme.spacing(1) }}
                    control={<Checkbox color='primary' checked={acepta} onChange={() => { setAcepta(!acepta); errores[0] = false; setErrores([...errores]) }} />}
                    label={<Typography style={{ color: errores[0] ? '#e53935' : null }} variant='body1'>Acepto.</Typography>}
                />
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Typography variant='body1' align='justify'><span style={{ fontWeight: 500 }}>AUTORIZACIÓN DE TRATAMIENTO DE DATOS PERSONALES</span></Typography>
                <Typography style={{ marginTop: theme.spacing(1) }} variant='body1' align='justify'>Autorizo al CNC a recolectar y almacenar los datos personales con fines de contacto para ser incluido en selección y evaluación para participar en la ejecución de proyectos cuando haya lugar. Esta autorización se pide para cumplir con la Ley Estatutaria 1581 de 2012 sobre la protección de datos personales reglamentada parcialmente por el Decreto 1377 de 2013. Usted tiene derecho a conocer, actualizar, rectificar y revocar sus datos personales que reposan en nuestras bases de datos en el momento en que lo considere necesario, mediante comunicación escrita al correo electrónico: cnc@cnccol.com.</Typography>
                <FormControlLabel
                    style={{ marginTop: theme.spacing(1) }}
                    control={<Checkbox color='primary' checked={acepta2} onChange={() => { setAcepta2(!acepta2); errores[1] = false; setErrores([...errores]) }} />}
                    label={<Typography style={{ color: errores[1] ? '#e53935' : null }} variant='body1'>Acepto.</Typography>}
                />
            </CardContent>
            <Divider style={{ marginBottom: theme.spacing(1) }} />
            <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Button
                    onClick={() => limpiar()}
                    variant='contained'
                    color='secondary'
                >
                    Limpiar
                </Button>
                <Button
                    onClick={() => disclaimer(acepta, acepta2)}
                    variant='contained'
                    color='primary'
                >
                    Registrar
                </Button>
            </CardActions>
        </Card>
    );
}

export default Disclaimer;