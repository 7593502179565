const municipios = [
    { daneMuni: 5002, municipio: "Abejorral", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 54003, municipio: "Abrego", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5004, municipio: "Abriaquí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 50006, municipio: "Acacias", daneDep: 50, departamento: "Meta" },
    { daneMuni: 27006, municipio: "Acandí", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 41006, municipio: "Acevedo", daneDep: 41, departamento: "Huila" },
    { daneMuni: 13006, municipio: "Achí", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 41013, municipio: "Agrado", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25001, municipio: "Agua de Dios", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 20011, municipio: "Aguachica", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 68013, municipio: "Aguada", daneDep: 68, departamento: "Santander" },
    { daneMuni: 17013, municipio: "Aguadas", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 85010, municipio: "Aguazul", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 20013, municipio: "Agustín Codazzi", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 41016, municipio: "Aipe", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25019, municipio: "Albán", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52019, municipio: "Albán", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 18029, municipio: "Albania", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 44035, municipio: "Albania", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 68020, municipio: "Albania", daneDep: 68, departamento: "Santander" },
    { daneMuni: 76020, municipio: "Alcalá", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 52022, municipio: "Aldana", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5021, municipio: "Alejandría", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 47030, municipio: "Algarrobo", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 41020, municipio: "Algeciras", daneDep: 41, departamento: "Huila" },
    { daneMuni: 19022, municipio: "Almaguer", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 15022, municipio: "Almeida", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 73024, municipio: "Alpujarra", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 41026, municipio: "Altamira", daneDep: 41, departamento: "Huila" },
    { daneMuni: 27025, municipio: "Alto Baudo", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 13030, municipio: "Altos del Rosario", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 73026, municipio: "Alvarado", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5030, municipio: "Amagá", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5031, municipio: "Amalfi", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73030, municipio: "Ambalema", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 25035, municipio: "Anapoima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52036, municipio: "Ancuyá", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76036, municipio: "Andalucía", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5034, municipio: "Andes", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5036, municipio: "Angelópolis", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5038, municipio: "Angostura", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25040, municipio: "Anolaima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5040, municipio: "Anorí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 17042, municipio: "Anserma", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 76041, municipio: "Ansermanuevo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5044, municipio: "Anza", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73043, municipio: "Anzoátegui", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5045, municipio: "Apartadó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 66045, municipio: "Apía", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 25599, municipio: "Apulo", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15047, municipio: "Aquitania", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 47053, municipio: "Aracataca", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 17050, municipio: "Aranzazu", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 68051, municipio: "Aratoca", daneDep: 68, departamento: "Santander" },
    { daneMuni: 81001, municipio: "Arauca", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 81065, municipio: "Arauquita", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 25053, municipio: "Arbeláez", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52051, municipio: "Arboleda", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 54051, municipio: "Arboledas", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5051, municipio: "Arboletes", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15051, municipio: "Arcabuco", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13042, municipio: "Arenal", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5055, municipio: "Argelia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 19050, municipio: "Argelia", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 76054, municipio: "Argelia", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 47058, municipio: "Ariguaní", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 13052, municipio: "Arjona", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5059, municipio: "Armenia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 63001, municipio: "Armenia", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 73055, municipio: "Armero", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 13062, municipio: "Arroyohondo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 20032, municipio: "Astrea", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 73067, municipio: "Ataco", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 27050, municipio: "Atrato", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 23068, municipio: "Ayapel", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 27073, municipio: "Bagadó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27075, municipio: "Bahía Solano", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27077, municipio: "Bajo Baudó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 19075, municipio: "Balboa", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 66075, municipio: "Balboa", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 8078, municipio: "Baranoa", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 41078, municipio: "Baraya", daneDep: 41, departamento: "Huila" },
    { daneMuni: 52079, municipio: "Barbacoas", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5079, municipio: "Barbosa", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68077, municipio: "Barbosa", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68079, municipio: "Barichara", daneDep: 68, departamento: "Santander" },
    { daneMuni: 50110, municipio: "Barranca de Upía", daneDep: 50, departamento: "Meta" },
    { daneMuni: 68081, municipio: "Barrancabermeja", daneDep: 68, departamento: "Santander" },
    { daneMuni: 44078, municipio: "Barrancas", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 13074, municipio: "Barranco de Loba", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 94343, municipio: "Barranco Minas", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 8001, municipio: "Barranquilla", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 20045, municipio: "Becerril", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 17088, municipio: "Belalcázar", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 52083, municipio: "Belén", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15087, municipio: "Belén", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 27086, municipio: "Belén de Bajira", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 18094, municipio: "Belén de Los Andaquies", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 66088, municipio: "Belén de Umbría", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 5088, municipio: "Bello", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5086, municipio: "Belmira", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25086, municipio: "Beltrán", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15090, municipio: "Berbeo", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5091, municipio: "Betania", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15092, municipio: "Betéitiva", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5093, municipio: "Betulia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68092, municipio: "Betulia", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25095, municipio: "Bituima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15097, municipio: "Boavita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 54099, municipio: "Bochalema", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 11001, municipio: "Bogotá D.C.", daneDep: 11, departamento: "Bogotá D.C." },
    { daneMuni: 25099, municipio: "Bojacá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27099, municipio: "Bojaya", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 19100, municipio: "Bolívar", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68101, municipio: "Bolívar", daneDep: 68, departamento: "Santander" },
    { daneMuni: 76100, municipio: "Bolívar", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 20060, municipio: "Bosconia", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 15104, municipio: "Boyacá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5107, municipio: "Briceño", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15106, municipio: "Briceño", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68001, municipio: "Bucaramanga", daneDep: 68, departamento: "Santander" },
    { daneMuni: 54109, municipio: "Bucarasica", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15109, municipio: "Buena Vista", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 76109, municipio: "Buenaventura", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 23079, municipio: "Buenavista", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 63111, municipio: "Buenavista", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 70110, municipio: "Buenavista", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 19110, municipio: "Buenos Aires", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 52110, municipio: "Buesaco", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76113, municipio: "Bugalagrande", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5113, municipio: "Buriticá", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15114, municipio: "Busbanzá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25120, municipio: "Cabrera", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68121, municipio: "Cabrera", daneDep: 68, departamento: "Santander" },
    { daneMuni: 50124, municipio: "Cabuyaro", daneDep: 50, departamento: "Meta" },
    { daneMuni: 94886, municipio: "Cacahual", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 5120, municipio: "Cáceres", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25123, municipio: "Cachipay", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54128, municipio: "Cachirá", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 54125, municipio: "Cácota", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5125, municipio: "Caicedo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 76122, municipio: "Caicedonia", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 70124, municipio: "Caimito", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 73124, municipio: "Cajamarca", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 19130, municipio: "Cajibío", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 25126, municipio: "Cajicá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 13140, municipio: "Calamar", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 95015, municipio: "Calamar", daneDep: 95, departamento: "Guaviare" },
    { daneMuni: 63130, municipio: "Calarcá", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 5129, municipio: "Caldas", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15131, municipio: "Caldas", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19137, municipio: "Caldono", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 76001, municipio: "Cali", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 68132, municipio: "California", daneDep: 68, departamento: "Santander" },
    { daneMuni: 76126, municipio: "Calima", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 19142, municipio: "Caloto", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 5134, municipio: "Campamento", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 8137, municipio: "Campo de La Cruz", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 41132, municipio: "Campoalegre", daneDep: 41, departamento: "Huila" },
    { daneMuni: 15135, municipio: "Campohermoso", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 23090, municipio: "Canalete", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 5138, municipio: "Cañasgordas", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 8141, municipio: "Candelaria", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 76130, municipio: "Candelaria", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 13160, municipio: "Cantagallo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 25148, municipio: "Caparrapí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68147, municipio: "Capitanejo", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25151, municipio: "Caqueza", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5142, municipio: "Caracolí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5145, municipio: "Caramanta", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68152, municipio: "Carcasí", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5147, municipio: "Carepa", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73148, municipio: "Carmen de Apicala", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 25154, municipio: "Carmen de Carupa", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27150, municipio: "Carmen del Darien", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 5150, municipio: "Carolina", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 13001, municipio: "Cartagena", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 18150, municipio: "Cartagena del Chairá", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 76147, municipio: "Cartago", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 97161, municipio: "Caruru", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 73152, municipio: "Casabianca", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 50150, municipio: "Castilla la Nueva", daneDep: 50, departamento: "Meta" },
    { daneMuni: 5154, municipio: "Caucasia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68160, municipio: "Cepitá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 23162, municipio: "Cereté", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 15162, municipio: "Cerinza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68162, municipio: "Cerrito", daneDep: 68, departamento: "Santander" },
    { daneMuni: 47161, municipio: "Cerro San Antonio", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 27160, municipio: "Cértegui", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 52240, municipio: "Chachagüí", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 25168, municipio: "Chaguaní", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 70230, municipio: "Chalán", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 85015, municipio: "Chámeza", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 73168, municipio: "Chaparral", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 68167, municipio: "Charalá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68169, municipio: "Charta", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25175, municipio: "Chía", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5172, municipio: "Chigorodó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68176, municipio: "Chimá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 23168, municipio: "Chimá", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 20175, municipio: "Chimichagua", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 54172, municipio: "Chinácota", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15172, municipio: "Chinavita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 17174, municipio: "Chinchiná", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 23182, municipio: "Chinú", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 25178, municipio: "Chipaque", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68179, municipio: "Chipatá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15176, municipio: "Chiquinquirá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15232, municipio: "Chíquiza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 20178, municipio: "Chiriguaná", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 15180, municipio: "Chiscas", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15183, municipio: "Chita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 54174, municipio: "Chitagá", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15185, municipio: "Chitaraque", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15187, municipio: "Chivatá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 47170, municipio: "Chivolo", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 15236, municipio: "Chivor", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25181, municipio: "Choachí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25183, municipio: "Chocontá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 13188, municipio: "Cicuco", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 47189, municipio: "Ciénaga", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 23189, municipio: "Ciénaga de Oro", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 15189, municipio: "Ciénega", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68190, municipio: "Cimitarra", daneDep: 68, departamento: "Santander" },
    { daneMuni: 63190, municipio: "Circasia", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 5190, municipio: "Cisneros", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5101, municipio: "Ciudad Bolívar", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 13222, municipio: "Clemencia", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5197, municipio: "Cocorná", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73200, municipio: "Coello", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 25200, municipio: "Cogua", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 41206, municipio: "Colombia", daneDep: 41, departamento: "Huila" },
    { daneMuni: 52203, municipio: "Colón", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 86219, municipio: "Colón", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 70204, municipio: "Coloso", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 15204, municipio: "Cómbita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5206, municipio: "Concepción", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68207, municipio: "Concepción", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5209, municipio: "Concordia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 47205, municipio: "Concordia", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 27205, municipio: "Condoto", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 68209, municipio: "Confines", daneDep: 68, departamento: "Santander" },
    { daneMuni: 52207, municipio: "Consaca", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 52210, municipio: "Contadero", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 68211, municipio: "Contratación", daneDep: 68, departamento: "Santander" },
    { daneMuni: 54206, municipio: "Convención", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5212, municipio: "Copacabana", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15212, municipio: "Coper", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13212, municipio: "Córdoba", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 52215, municipio: "Córdoba", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 63212, municipio: "Córdoba", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 19212, municipio: "Corinto", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68217, municipio: "Coromoro", daneDep: 68, departamento: "Santander" },
    { daneMuni: 70215, municipio: "Corozal", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 15215, municipio: "Corrales", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25214, municipio: "Cota", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 23300, municipio: "Cotorra", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 15218, municipio: "Covarachía", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 70221, municipio: "Coveñas", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 73217, municipio: "Coyaima", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 81220, municipio: "Cravo Norte", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 52224, municipio: "Cuaspud", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15223, municipio: "Cubará", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 50223, municipio: "Cubarral", daneDep: 50, departamento: "Meta" },
    { daneMuni: 15224, municipio: "Cucaita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25224, municipio: "Cucunubá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54001, municipio: "Cúcuta", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 54223, municipio: "Cucutilla", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15226, municipio: "Cuítiva", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 50226, municipio: "Cumaral", daneDep: 50, departamento: "Meta" },
    { daneMuni: 99773, municipio: "Cumaribo", daneDep: 99, departamento: "Vichada" },
    { daneMuni: 52227, municipio: "Cumbal", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 52233, municipio: "Cumbitara", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 73226, municipio: "Cunday", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 18205, municipio: "Curillo", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 68229, municipio: "Curití", daneDep: 68, departamento: "Santander" },
    { daneMuni: 20228, municipio: "Curumaní", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 5234, municipio: "Dabeiba", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 76233, municipio: "Dagua", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 44090, municipio: "Dibula", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 44098, municipio: "Distracción", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 73236, municipio: "Dolores", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5237, municipio: "Don Matías", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 66170, municipio: "Dosquebradas", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 15238, municipio: "Duitama", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 54239, municipio: "Durania", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5240, municipio: "Ebéjico", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 76243, municipio: "El Águila", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5250, municipio: "El Bagre", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 47245, municipio: "El Banco", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 76246, municipio: "El Cairo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 50245, municipio: "El Calvario", daneDep: 50, departamento: "Meta" },
    { daneMuni: 27135, municipio: "El Cantón del San Pablo", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 54245, municipio: "El Carmen", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 27245, municipio: "El Carmen de Atrato", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 13244, municipio: "El Carmen de Bolívar", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 68235, municipio: "El Carmen de Chucurí", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5148, municipio: "El Carmen de Viboral", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 50251, municipio: "El Castillo", daneDep: 50, departamento: "Meta" },
    { daneMuni: 76248, municipio: "El Cerrito", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 52250, municipio: "El Charco", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15244, municipio: "El Cocuy", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25245, municipio: "El Colegio", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 20238, municipio: "El Copey", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 18247, municipio: "El Doncello", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 50270, municipio: "El Dorado", daneDep: 50, departamento: "Meta" },
    { daneMuni: 76250, municipio: "El Dovio", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 91263, municipio: "El Encanto", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 15248, municipio: "El Espino", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68245, municipio: "El Guacamayo", daneDep: 68, departamento: "Santander" },
    { daneMuni: 13248, municipio: "El Guamo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 27250, municipio: "El Litoral del San Juan", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 44110, municipio: "El Molino", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 20250, municipio: "El Paso", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 18256, municipio: "El Paujil", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 52254, municipio: "El Peñol", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 13268, municipio: "El Peñón", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 25258, municipio: "El Peñón", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68250, municipio: "El Peñón", daneDep: 68, departamento: "Santander" },
    { daneMuni: 47258, municipio: "El Piñon", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 68255, municipio: "El Playón", daneDep: 68, departamento: "Santander" },
    { daneMuni: 47268, municipio: "El Retén", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 95025, municipio: "El Retorno", daneDep: 95, departamento: "Guaviare" },
    { daneMuni: 70233, municipio: "El Roble", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25260, municipio: "El Rosal", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52256, municipio: "El Rosario", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5697, municipio: "El Santuario", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52258, municipio: "El Tablón de Gómez", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 19256, municipio: "El Tambo", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 52260, municipio: "El Tambo", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 54250, municipio: "El Tarra", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 54261, municipio: "El Zulia", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 41244, municipio: "Elías", daneDep: 41, departamento: "Huila" },
    { daneMuni: 68264, municipio: "Encino", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68266, municipio: "Enciso", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5264, municipio: "Entrerrios", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5266, municipio: "Envigado", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73268, municipio: "Espinal", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 25269, municipio: "Facatativá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 73270, municipio: "Falan", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 17272, municipio: "Filadelfia", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 63272, municipio: "Filandia", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 15272, municipio: "Firavitoba", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 73275, municipio: "Flandes", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 18001, municipio: "Florencia", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 19290, municipio: "Florencia", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 15276, municipio: "Floresta", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68271, municipio: "Florián", daneDep: 68, departamento: "Santander" },
    { daneMuni: 76275, municipio: "Florida", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 68276, municipio: "Floridablanca", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25279, municipio: "Fomeque", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 44279, municipio: "Fonseca", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 81300, municipio: "Fortul", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 25281, municipio: "Fosca", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52520, municipio: "Francisco Pizarro", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5282, municipio: "Fredonia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73283, municipio: "Fresno", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5284, municipio: "Frontino", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 50287, municipio: "Fuente de Oro", daneDep: 50, departamento: "Meta" },
    { daneMuni: 47288, municipio: "Fundación", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 52287, municipio: "Funes", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 25286, municipio: "Funza", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25288, municipio: "Fúquene", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25290, municipio: "Fusagasugá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25293, municipio: "Gachala", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25295, municipio: "Gachancipá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15293, municipio: "Gachantivá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25297, municipio: "Gachetá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68296, municipio: "Galán", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8296, municipio: "Galapa", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 70235, municipio: "Galeras", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25299, municipio: "Gama", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 20295, municipio: "Gamarra", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 68298, municipio: "Gambita", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15296, municipio: "Gameza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15299, municipio: "Garagoa", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 41298, municipio: "Garzón", daneDep: 41, departamento: "Huila" },
    { daneMuni: 63302, municipio: "Génova", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 41306, municipio: "Gigante", daneDep: 41, departamento: "Huila" },
    { daneMuni: 76306, municipio: "Ginebra", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5306, municipio: "Giraldo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25307, municipio: "Girardot", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5308, municipio: "Girardota", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68307, municipio: "Girón", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5310, municipio: "Gómez Plata", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 20310, municipio: "González", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 54313, municipio: "Gramalote", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5313, municipio: "Granada", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25312, municipio: "Granada", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 50313, municipio: "Granada", daneDep: 50, departamento: "Meta" },
    { daneMuni: 68318, municipio: "Guaca", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15317, municipio: "Guacamayas", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 76318, municipio: "Guacarí", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 19300, municipio: "Guachené", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 25317, municipio: "Guachetá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52317, municipio: "Guachucal", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76111, municipio: "Guadalajara de Buga", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5315, municipio: "Guadalupe", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 41319, municipio: "Guadalupe", daneDep: 41, departamento: "Huila" },
    { daneMuni: 68320, municipio: "Guadalupe", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25320, municipio: "Guaduas", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52320, municipio: "Guaitarilla", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 52323, municipio: "Gualmatán", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 47318, municipio: "Guamal", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 50318, municipio: "Guamal", daneDep: 50, departamento: "Meta" },
    { daneMuni: 73319, municipio: "Guamo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 19318, municipio: "Guapi", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68322, municipio: "Guapotá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 70265, municipio: "Guaranda", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 5318, municipio: "Guarne", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25322, municipio: "Guasca", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5321, municipio: "Guatapé", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25324, municipio: "Guataquí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25326, municipio: "Guatavita", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15322, municipio: "Guateque", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 66318, municipio: "Guática", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 68324, municipio: "Guavatá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25328, municipio: "Guayabal de Siquima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25335, municipio: "Guayabetal", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15325, municipio: "Guayatá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68327, municipio: "Güepsa", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15332, municipio: "Güicán", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25339, municipio: "Gutiérrez", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54344, municipio: "Hacarí", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 13300, municipio: "Hatillo de Loba", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 68344, municipio: "Hato", daneDep: 68, departamento: "Santander" },
    { daneMuni: 85125, municipio: "Hato Corozal", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 44378, municipio: "Hatonuevo", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 5347, municipio: "Heliconia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 54347, municipio: "Herrán", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 73347, municipio: "Herveo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5353, municipio: "Hispania", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 41349, municipio: "Hobo", daneDep: 41, departamento: "Huila" },
    { daneMuni: 73349, municipio: "Honda", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 73001, municipio: "Ibagué", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 73352, municipio: "Icononzo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 52352, municipio: "Iles", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 52354, municipio: "Imués", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 94001, municipio: "Inírida", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 19355, municipio: "Inzá", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 52356, municipio: "Ipiales", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 41357, municipio: "Iquira", daneDep: 41, departamento: "Huila" },
    { daneMuni: 41359, municipio: "Isnos", daneDep: 41, departamento: "Huila" },
    { daneMuni: 27361, municipio: "Istmina", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 5360, municipio: "Itagui", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5361, municipio: "Ituango", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15362, municipio: "Iza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19364, municipio: "Jambaló", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 76364, municipio: "Jamundí", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5364, municipio: "Jardín", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15367, municipio: "Jenesano", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5368, municipio: "Jericó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15368, municipio: "Jericó", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25368, municipio: "Jerusalén", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68368, municipio: "Jesús María", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68370, municipio: "Jordán", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8372, municipio: "Juan de Acosta", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 25372, municipio: "Junín", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27372, municipio: "Juradó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 23350, municipio: "La Apartada", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 41378, municipio: "La Argentina", daneDep: 41, departamento: "Huila" },
    { daneMuni: 68377, municipio: "La Belleza", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25377, municipio: "La Calera", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15380, municipio: "La Capilla", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5376, municipio: "La Ceja", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 66383, municipio: "La Celia", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 91405, municipio: "La Chorrera", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 52378, municipio: "La Cruz", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76377, municipio: "La Cumbre", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 17380, municipio: "La Dorada", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 54385, municipio: "La Esperanza", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 5380, municipio: "La Estrella", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52381, municipio: "La Florida", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 20383, municipio: "La Gloria", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 94885, municipio: "La Guadalupe", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 20400, municipio: "La Jagua de Ibirico", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 44420, municipio: "La Jagua del Pilar", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 52385, municipio: "La Llanada", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 50350, municipio: "La Macarena", daneDep: 50, departamento: "Meta" },
    { daneMuni: 17388, municipio: "La Merced", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 25386, municipio: "La Mesa", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 18410, municipio: "La Montañita", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 25394, municipio: "La Palma", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 20621, municipio: "La Paz", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 68397, municipio: "La Paz", daneDep: 68, departamento: "Santander" },
    { daneMuni: 91407, municipio: "La Pedrera", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 25398, municipio: "La Peña", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5390, municipio: "La Pintada", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 41396, municipio: "La Plata", daneDep: 41, departamento: "Huila" },
    { daneMuni: 54398, municipio: "La Playa", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 99524, municipio: "La Primavera", daneDep: 99, departamento: "Vichada" },
    { daneMuni: 85136, municipio: "La Salina", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 19392, municipio: "La Sierra", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 63401, municipio: "La Tebaida", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 52390, municipio: "La Tola", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5400, municipio: "La Unión", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52399, municipio: "La Unión", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 70400, municipio: "La Unión", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 76400, municipio: "La Unión", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15403, municipio: "La Uvita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19397, municipio: "La Vega", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 25402, municipio: "La Vega", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15401, municipio: "La Victoria", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 91430, municipio: "La Victoria", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 76403, municipio: "La Victoria", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 66400, municipio: "La Virginia", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 54377, municipio: "Labateca", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15377, municipio: "Labranzagrande", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68385, municipio: "Landázuri", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68406, municipio: "Lebríja", daneDep: 68, departamento: "Santander" },
    { daneMuni: 86573, municipio: "Leguízamo", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 52405, municipio: "Leiva", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 50400, municipio: "Lejanías", daneDep: 50, departamento: "Meta" },
    { daneMuni: 25407, municipio: "Lenguazaque", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 73408, municipio: "Lérida", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 91001, municipio: "Leticia", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 73411, municipio: "Líbano", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5411, municipio: "Liborina", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52411, municipio: "Linares", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 27413, municipio: "Lloró", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 19418, municipio: "López", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 23417, municipio: "Lorica", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 52418, municipio: "Los Andes", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 23419, municipio: "Los Córdobas", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 70418, municipio: "Los Palmitos", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 54405, municipio: "Los Patios", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 68418, municipio: "Los Santos", daneDep: 68, departamento: "Santander" },
    { daneMuni: 54418, municipio: "Lourdes", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 8421, municipio: "Luruaco", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 15425, municipio: "Macanal", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68425, municipio: "Macaravita", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5425, municipio: "Maceo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25426, municipio: "Macheta", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25430, municipio: "Madrid", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 13430, municipio: "Magangué", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 52427, municipio: "Magüí", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 13433, municipio: "Mahates", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 44430, municipio: "Maicao", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 70429, municipio: "Majagual", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 68432, municipio: "Málaga", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8433, municipio: "Malambo", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 52435, municipio: "Mallama", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 8436, municipio: "Manatí", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 20443, municipio: "Manaure", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 44560, municipio: "Manaure", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 85139, municipio: "Maní", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 17001, municipio: "Manizales", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 25436, municipio: "Manta", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 17433, municipio: "Manzanares", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 50325, municipio: "Mapiripán", daneDep: 50, departamento: "Meta" },
    { daneMuni: 94663, municipio: "Mapiripana", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 13440, municipio: "Margarita", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 13442, municipio: "María la Baja", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5440, municipio: "Marinilla", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15442, municipio: "Maripí", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 73443, municipio: "Mariquita", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 17442, municipio: "Marmato", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 17444, municipio: "Marquetalia", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 66440, municipio: "Marsella", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 17446, municipio: "Marulanda", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 68444, municipio: "Matanza", daneDep: 68, departamento: "Santander" },
    { daneMuni: 5001, municipio: "Medellín", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25438, municipio: "Medina", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27425, municipio: "Medio Atrato", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27430, municipio: "Medio Baudó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27450, municipio: "Medio San Juan", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 73449, municipio: "Melgar", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 19450, municipio: "Mercaderes", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 50330, municipio: "Mesetas", daneDep: 50, departamento: "Meta" },
    { daneMuni: 18460, municipio: "Milán", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 15455, municipio: "Miraflores", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 95200, municipio: "Miraflores", daneDep: 95, departamento: "Guaviare" },
    { daneMuni: 19455, municipio: "Miranda", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 91460, municipio: "Miriti Paraná", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 66456, municipio: "Mistrató", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 97001, municipio: "Mitú", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 86001, municipio: "Mocoa", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 68464, municipio: "Mogotes", daneDep: 68, departamento: "Santander" },
    { daneMuni: 68468, municipio: "Molagavita", daneDep: 68, departamento: "Santander" },
    { daneMuni: 23464, municipio: "Momil", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 13468, municipio: "Mompós", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 15464, municipio: "Mongua", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15466, municipio: "Monguí", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15469, municipio: "Moniquirá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 23500, municipio: "Moñitos", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 5467, municipio: "Montebello", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 13458, municipio: "Montecristo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 23466, municipio: "Montelíbano", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 63470, municipio: "Montenegro", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 23001, municipio: "Montería", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 85162, municipio: "Monterrey", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 13473, municipio: "Morales", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 19473, municipio: "Morales", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 18479, municipio: "Morelia", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 94888, municipio: "Morichal", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 70473, municipio: "Morroa", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25473, municipio: "Mosquera", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52473, municipio: "Mosquera", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15476, municipio: "Motavita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 73461, municipio: "Murillo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5475, municipio: "Murindó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5480, municipio: "Mutatá", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 54480, municipio: "Mutiscua", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15480, municipio: "Muzo", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5483, municipio: "Nariño", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25483, municipio: "Nariño", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52480, municipio: "Nariño", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 41483, municipio: "Nátaga", daneDep: 41, departamento: "Huila" },
    { daneMuni: 73483, municipio: "Natagaima", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5495, municipio: "Nechí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5490, municipio: "Necoclí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 17486, municipio: "Neira", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 41001, municipio: "Neiva", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25486, municipio: "Nemocón", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25488, municipio: "Nilo", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25489, municipio: "Nimaima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15491, municipio: "Nobsa", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25491, municipio: "Nocaima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 17495, municipio: "Norcasia", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 13490, municipio: "Norosí", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 27491, municipio: "Nóvita", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 47460, municipio: "Nueva Granada", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 15494, municipio: "Nuevo Colón", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 85225, municipio: "Nunchía", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 27495, municipio: "Nuquí", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 76497, municipio: "Obando", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 68498, municipio: "Ocamonte", daneDep: 68, departamento: "Santander" },
    { daneMuni: 54498, municipio: "Ocaña", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 68500, municipio: "Oiba", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15500, municipio: "Oicatá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5501, municipio: "Olaya", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52490, municipio: "Olaya Herrera", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 68502, municipio: "Onzaga", daneDep: 68, departamento: "Santander" },
    { daneMuni: 41503, municipio: "Oporapa", daneDep: 41, departamento: "Huila" },
    { daneMuni: 86320, municipio: "Orito", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 85230, municipio: "Orocué", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 73504, municipio: "Ortega", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 52506, municipio: "Ospina", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15507, municipio: "Otanche", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 70508, municipio: "Ovejas", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 15511, municipio: "Pachavita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25513, municipio: "Pacho", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 97511, municipio: "Pacoa", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 17513, municipio: "Pácora", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 19513, municipio: "Padilla", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 19517, municipio: "Páez", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 15514, municipio: "Páez", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 41518, municipio: "Paicol", daneDep: 41, departamento: "Huila" },
    { daneMuni: 20517, municipio: "Pailitas", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 25518, municipio: "Paime", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15516, municipio: "Paipa", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15518, municipio: "Pajarito", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 41524, municipio: "Palermo", daneDep: 41, departamento: "Huila" },
    { daneMuni: 17524, municipio: "Palestina", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 41530, municipio: "Palestina", daneDep: 41, departamento: "Huila" },
    { daneMuni: 68522, municipio: "Palmar", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8520, municipio: "Palmar de Varela", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 68524, municipio: "Palmas del Socorro", daneDep: 68, departamento: "Santander" },
    { daneMuni: 76520, municipio: "Palmira", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 70523, municipio: "Palmito", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 73520, municipio: "Palocabildo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 54518, municipio: "Pamplona", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 54520, municipio: "Pamplonita", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 94887, municipio: "Pana Pana", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 25524, municipio: "Pandi", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15522, municipio: "Panqueba", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 97777, municipio: "Papunaua", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 68533, municipio: "Páramo", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25530, municipio: "Paratebueno", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25535, municipio: "Pasca", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52001, municipio: "Pasto", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 19532, municipio: "Patía", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 15531, municipio: "Pauna", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15533, municipio: "Paya", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 85250, municipio: "Paz de Ariporo", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 15537, municipio: "Paz de Río", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 47541, municipio: "Pedraza", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 20550, municipio: "Pelaya", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 5541, municipio: "Peñol", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 17541, municipio: "Pensilvania", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 5543, municipio: "Peque", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 66001, municipio: "Pereira", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 15542, municipio: "Pesca", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19533, municipio: "Piamonte", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68547, municipio: "Piedecuesta", daneDep: 68, departamento: "Santander" },
    { daneMuni: 73547, municipio: "Piedras", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 19548, municipio: "Piendamó", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 63548, municipio: "Pijao", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 47545, municipio: "Pijiño del Carmen", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 68549, municipio: "Pinchote", daneDep: 68, departamento: "Santander" },
    { daneMuni: 13549, municipio: "Pinillos", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 8549, municipio: "Piojó", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 15550, municipio: "Pisba", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 41548, municipio: "Pital", daneDep: 41, departamento: "Huila" },
    { daneMuni: 41551, municipio: "Pitalito", daneDep: 41, departamento: "Huila" },
    { daneMuni: 47551, municipio: "Pivijay", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 73555, municipio: "Planadas", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 23555, municipio: "Planeta Rica", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 47555, municipio: "Plato", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 52540, municipio: "Policarpa", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 8558, municipio: "Polonuevo", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 8560, municipio: "Ponedera", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 19001, municipio: "Popayán", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 85263, municipio: "Pore", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 52560, municipio: "Potosí", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76563, municipio: "Pradera", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 73563, municipio: "Prado", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 52565, municipio: "Providencia", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 88564, municipio: "Providencia", daneDep: 88, departamento: "Archipiélago de San Andrés, Providencia y Santa Catalina" },
    { daneMuni: 20570, municipio: "Pueblo Bello", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 23570, municipio: "Pueblo Nuevo", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 66572, municipio: "Pueblo Rico", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 47570, municipio: "Pueblo Viejo", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 5576, municipio: "Pueblorrico", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68572, municipio: "Puente Nacional", daneDep: 68, departamento: "Santander" },
    { daneMuni: 52573, municipio: "Puerres", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 91530, municipio: "Puerto Alegría", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 91536, municipio: "Puerto Arica", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 86568, municipio: "Puerto Asís", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 5579, municipio: "Puerto Berrío", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15572, municipio: "Puerto Boyacá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 86569, municipio: "Puerto Caicedo", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 99001, municipio: "Puerto Carreño", daneDep: 99, departamento: "Vichada" },
    { daneMuni: 8573, municipio: "Puerto Colombia", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 94884, municipio: "Puerto Colombia", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 50450, municipio: "Puerto Concordia", daneDep: 50, departamento: "Meta" },
    { daneMuni: 23574, municipio: "Puerto Escondido", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 50568, municipio: "Puerto Gaitán", daneDep: 50, departamento: "Meta" },
    { daneMuni: 86571, municipio: "Puerto Guzmán", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 23580, municipio: "Puerto Libertador", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 50577, municipio: "Puerto Lleras", daneDep: 50, departamento: "Meta" },
    { daneMuni: 50573, municipio: "Puerto López", daneDep: 50, departamento: "Meta" },
    { daneMuni: 5585, municipio: "Puerto Nare", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 91540, municipio: "Puerto Nariño", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 68573, municipio: "Puerto Parra", daneDep: 68, departamento: "Santander" },
    { daneMuni: 18592, municipio: "Puerto Rico", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 50590, municipio: "Puerto Rico", daneDep: 50, departamento: "Meta" },
    { daneMuni: 81591, municipio: "Puerto Rondón", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 25572, municipio: "Puerto Salgar", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 91669, municipio: "Puerto Santander", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 54553, municipio: "Puerto Santander", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 19573, municipio: "Puerto Tejada", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 5591, municipio: "Puerto Triunfo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68575, municipio: "Puerto Wilches", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25580, municipio: "Pulí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52585, municipio: "Pupiales", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 19585, municipio: "Puracé", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 73585, municipio: "Purificación", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 23586, municipio: "Purísima", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 25592, municipio: "Quebradanegra", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25594, municipio: "Quetame", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27001, municipio: "Quibdó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 63594, municipio: "Quimbaya", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 66594, municipio: "Quinchía", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 15580, municipio: "Quípama", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25596, municipio: "Quipile", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54599, municipio: "Ragonvalia", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15599, municipio: "Ramiriquí", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15600, municipio: "Ráquira", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 85279, municipio: "Recetor", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 13580, municipio: "Regidor", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5604, municipio: "Remedios", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 47605, municipio: "Remolino", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 8606, municipio: "Repelón", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 50606, municipio: "Restrepo", daneDep: 50, departamento: "Meta" },
    { daneMuni: 76606, municipio: "Restrepo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 5607, municipio: "Retiro", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25612, municipio: "Ricaurte", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52612, municipio: "Ricaurte", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 73616, municipio: "Rio Blanco", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 20614, municipio: "Río de Oro", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 27580, municipio: "Río Iro", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27600, municipio: "Río Quito", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 13600, municipio: "Río Viejo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 76616, municipio: "Riofrío", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 44001, municipio: "Riohacha", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 5615, municipio: "Rionegro", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68615, municipio: "Rionegro", daneDep: 68, departamento: "Santander" },
    { daneMuni: 17614, municipio: "Riosucio", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 27615, municipio: "Riosucio", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 17616, municipio: "Risaralda", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 41615, municipio: "Rivera", daneDep: 41, departamento: "Huila" },
    { daneMuni: 52621, municipio: "Roberto Payán", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 76622, municipio: "Roldanillo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 73622, municipio: "Roncesvalles", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 15621, municipio: "Rondón", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19622, municipio: "Rosas", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 73624, municipio: "Rovira", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 68655, municipio: "Sabana de Torres", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8634, municipio: "Sabanagrande", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 5628, municipio: "Sabanalarga", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 8638, municipio: "Sabanalarga", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 85300, municipio: "Sabanalarga", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 47660, municipio: "Sabanas de San Angel", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 5631, municipio: "Sabaneta", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15632, municipio: "Saboyá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 85315, municipio: "Sácama", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 15638, municipio: "Sáchica", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 23660, municipio: "Sahagún", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 41660, municipio: "Saladoblanco", daneDep: 41, departamento: "Huila" },
    { daneMuni: 17653, municipio: "Salamina", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 47675, municipio: "Salamina", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 54660, municipio: "Salazar", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 73671, municipio: "Saldaña", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 63690, municipio: "Salento", daneDep: 63, departamento: "Quindío" },
    { daneMuni: 5642, municipio: "Salgar", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15646, municipio: "Samacá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 17662, municipio: "Samaná", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 52678, municipio: "Samaniego", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 70670, municipio: "Sampués", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 41668, municipio: "San Agustín", daneDep: 41, departamento: "Huila" },
    { daneMuni: 20710, municipio: "San Alberto", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 68669, municipio: "San Andrés", daneDep: 68, departamento: "Santander" },
    { daneMuni: 88001, municipio: "San Andrés", daneDep: 88, departamento: "Archipiélago de San Andrés, Providencia y Santa Catalina" },
    { daneMuni: 5647, municipio: "San Andrés de Cuerquía", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52835, municipio: "San Andrés de Tumaco", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 23670, municipio: "San Andrés Sotavento", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 23672, municipio: "San Antero", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 73675, municipio: "San Antonio", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 25645, municipio: "San Antonio del Tequendama", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 68673, municipio: "San Benito", daneDep: 68, departamento: "Santander" },
    { daneMuni: 70678, municipio: "San Benito Abad", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25649, municipio: "San Bernardo", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52685, municipio: "San Bernardo", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 23675, municipio: "San Bernardo del Viento", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 54670, municipio: "San Calixto", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 23678, municipio: "San Carlos", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 5649, municipio: "San Carlos", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 50680, municipio: "San Carlos de Guaroa", daneDep: 50, departamento: "Meta" },
    { daneMuni: 25653, municipio: "San Cayetano", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54673, municipio: "San Cayetano", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 13620, municipio: "San Cristóbal", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 20750, municipio: "San Diego", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 15660, municipio: "San Eduardo", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13647, municipio: "San Estanislao", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 94883, municipio: "San Felipe", daneDep: 94, departamento: "Guainía" },
    { daneMuni: 13650, municipio: "San Fernando", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5652, municipio: "San Francisco", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25658, municipio: "San Francisco", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 86755, municipio: "San Francisco", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 68679, municipio: "San Gil", daneDep: 68, departamento: "Santander" },
    { daneMuni: 13654, municipio: "San Jacinto", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 13655, municipio: "San Jacinto del Cauca", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5656, municipio: "San Jerónimo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68682, municipio: "San Joaquín", daneDep: 68, departamento: "Santander" },
    { daneMuni: 17665, municipio: "San José", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 5658, municipio: "San José de La Montaña", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68684, municipio: "San José de Miranda", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15664, municipio: "San José de Pare", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 23682, municipio: "San José de Uré", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 18610, municipio: "San José del Fragua", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 95001, municipio: "San José del Guaviare", daneDep: 95, departamento: "Guaviare" },
    { daneMuni: 27660, municipio: "San José del Palmar", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 50683, municipio: "San Juan de Arama", daneDep: 50, departamento: "Meta" },
    { daneMuni: 70702, municipio: "San Juan de Betulia", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25662, municipio: "San Juan de Río Seco", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5659, municipio: "San Juan de Urabá", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 44650, municipio: "San Juan del Cesar", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 13657, municipio: "San Juan Nepomuceno", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 50686, municipio: "San Juanito", daneDep: 50, departamento: "Meta" },
    { daneMuni: 52687, municipio: "San Lorenzo", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5660, municipio: "San Luis", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 73678, municipio: "San Luis", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 85325, municipio: "San Luis de Gaceno", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 15667, municipio: "San Luis de Gaceno", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 70742, municipio: "San Luis de Sincé", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 70708, municipio: "San Marcos", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 20770, municipio: "San Martín", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 50689, municipio: "San Martín", daneDep: 50, departamento: "Meta" },
    { daneMuni: 13667, municipio: "San Martín de Loba", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 15673, municipio: "San Mateo", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68686, municipio: "San Miguel", daneDep: 68, departamento: "Santander" },
    { daneMuni: 86757, municipio: "San Miguel", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 15676, municipio: "San Miguel de Sema", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 70713, municipio: "San Onofre", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 52693, municipio: "San Pablo", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 15681, municipio: "San Pablo de Borbur", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13670, municipio: "San Pablo de Borbur", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5664, municipio: "San Pedro", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 70717, municipio: "San Pedro", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 76670, municipio: "San Pedro", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 52694, municipio: "San Pedro de Cartago", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5665, municipio: "San Pedro de Uraba", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 23686, municipio: "San Pelayo", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 5667, municipio: "San Rafael", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5670, municipio: "San Roque", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 19693, municipio: "San Sebastián", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 47692, municipio: "San Sebastián de Buenavista", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 5674, municipio: "San Vicente", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68689, municipio: "San Vicente de Chucurí", daneDep: 68, departamento: "Santander" },
    { daneMuni: 18753, municipio: "San Vicente del Caguán", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 47703, municipio: "San Zenón", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 52683, municipio: "Sandoná", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 47707, municipio: "Santa Ana", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 5679, municipio: "Santa Bárbara", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52696, municipio: "Santa Bárbara", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 68705, municipio: "Santa Bárbara", daneDep: 68, departamento: "Santander" },
    { daneMuni: 47720, municipio: "Santa Bárbara de Pinto", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 13673, municipio: "Santa Catalina", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 68720, municipio: "Santa Helena del Opón", daneDep: 68, departamento: "Santander" },
    { daneMuni: 73686, municipio: "Santa Isabel", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 8675, municipio: "Santa Lucía", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 15690, municipio: "Santa María", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 41676, municipio: "Santa María", daneDep: 41, departamento: "Huila" },
    { daneMuni: 47001, municipio: "Santa Marta", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 13683, municipio: "Santa Rosa", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 19701, municipio: "Santa Rosa", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 66682, municipio: "Santa Rosa de Cabal", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 5686, municipio: "Santa Rosa de Osos", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15693, municipio: "Santa Rosa de Viterbo", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13688, municipio: "Santa Rosa del Sur", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 99624, municipio: "Santa Rosalía", daneDep: 99, departamento: "Vichada" },
    { daneMuni: 15696, municipio: "Santa Sofía", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 52699, municipio: "Santacruz", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 5042, municipio: "Santafé de Antioquia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15686, municipio: "Santana", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19698, municipio: "Santander de Quilichao", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 86760, municipio: "Santiago", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 54680, municipio: "Santiago", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 70820, municipio: "Santiago de Tolú", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 5690, municipio: "Santo Domingo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 8685, municipio: "Santo Tomás", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 66687, municipio: "Santuario", daneDep: 66, departamento: "Risaralda" },
    { daneMuni: 52720, municipio: "Sapuyes", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 81736, municipio: "Saravena", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 54720, municipio: "Sardinata", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 25718, municipio: "Sasaima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15720, municipio: "Sativanorte", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15723, municipio: "Sativasur", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5736, municipio: "Segovia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25736, municipio: "Sesquilé", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 76736, municipio: "Sevilla", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15740, municipio: "Siachoque", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25740, municipio: "Sibaté", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 86749, municipio: "Sibundoy", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 54743, municipio: "Silos", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 25743, municipio: "Silvania", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 19743, municipio: "Silvia", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68745, municipio: "Simacota", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25745, municipio: "Simijaca", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 13744, municipio: "Simití", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 70001, municipio: "Sincelejo", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 27745, municipio: "Sipí", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 47745, municipio: "Sitionuevo", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 25754, municipio: "Soacha", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15753, municipio: "Soatá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15757, municipio: "Socha", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 68755, municipio: "Socorro", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15755, municipio: "Socotá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15759, municipio: "Sogamoso", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 18756, municipio: "Solano", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 8758, municipio: "Soledad", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 18785, municipio: "Solita", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 15761, municipio: "Somondoco", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5756, municipio: "Sonsón", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5761, municipio: "Sopetrán", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 13760, municipio: "Soplaviento", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 25758, municipio: "Sopó", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15762, municipio: "Sora", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15764, municipio: "Soracá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15763, municipio: "Sotaquirá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19760, municipio: "Sotara", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68770, municipio: "Suaita", daneDep: 68, departamento: "Santander" },
    { daneMuni: 8770, municipio: "Suan", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 19780, municipio: "Suárez", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 73770, municipio: "Suárez", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 41770, municipio: "Suaza", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25769, municipio: "Subachoque", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 19785, municipio: "Sucre", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 68773, municipio: "Sucre", daneDep: 68, departamento: "Santander" },
    { daneMuni: 70771, municipio: "Sucre", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 25772, municipio: "Suesca", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25777, municipio: "Supatá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 17777, municipio: "Supía", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 68780, municipio: "Suratá", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25779, municipio: "Susa", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15774, municipio: "Susacón", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15776, municipio: "Sutamarchán", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25781, municipio: "Sutatausa", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15778, municipio: "Sutatenza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25785, municipio: "Tabio", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27787, municipio: "Tadó", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 13780, municipio: "Talaigua Nuevo", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 20787, municipio: "Tamalameque", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 85400, municipio: "Támara", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 81794, municipio: "Tame", daneDep: 81, departamento: "Arauca" },
    { daneMuni: 5789, municipio: "Támesis", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 52786, municipio: "Taminango", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 52788, municipio: "Tangua", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 97666, municipio: "Taraira", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 91798, municipio: "Tarapacá", daneDep: 91, departamento: "Amazonas" },
    { daneMuni: 5790, municipio: "Tarazá", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 41791, municipio: "Tarqui", daneDep: 41, departamento: "Huila" },
    { daneMuni: 5792, municipio: "Tarso", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15790, municipio: "Tasco", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 85410, municipio: "Tauramena", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 25793, municipio: "Tausa", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 41799, municipio: "Tello", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25797, municipio: "Tena", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 47798, municipio: "Tenerife", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 25799, municipio: "Tenjo", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15798, municipio: "Tenza", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 54800, municipio: "Teorama", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 41801, municipio: "Teruel", daneDep: 41, departamento: "Huila" },
    { daneMuni: 41797, municipio: "Tesalia", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25805, municipio: "Tibacuy", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15804, municipio: "Tibaná", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15806, municipio: "Tibasosa", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25807, municipio: "Tibirita", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54810, municipio: "Tibú", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 23807, municipio: "Tierralta", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 41807, municipio: "Timaná", daneDep: 41, departamento: "Huila" },
    { daneMuni: 19807, municipio: "Timbío", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 19809, municipio: "Timbiquí", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 15808, municipio: "Tinjacá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15810, municipio: "Tipacoque", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 13810, municipio: "Tiquisio", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5809, municipio: "Titiribí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15814, municipio: "Toca", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25815, municipio: "Tocaima", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25817, municipio: "Tocancipá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15816, municipio: "Togüí", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 5819, municipio: "Toledo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 54820, municipio: "Toledo", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 70823, municipio: "Tolú Viejo", daneDep: 70, departamento: "Sucre" },
    { daneMuni: 68820, municipio: "Tona", daneDep: 68, departamento: "Santander" },
    { daneMuni: 15820, municipio: "Tópaga", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25823, municipio: "Topaipí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 19821, municipio: "Toribio", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 76823, municipio: "Toro", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15822, municipio: "Tota", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 19824, municipio: "Totoró", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 85430, municipio: "Trinidad", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 76828, municipio: "Trujillo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 8832, municipio: "Tubará", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 23815, municipio: "Tuchín", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 76834, municipio: "Tuluá", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15001, municipio: "Tunja", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15832, municipio: "Tununguá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 52838, municipio: "Túquerres", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 13836, municipio: "Turbaco", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 13838, municipio: "Turbaná", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 5837, municipio: "Turbo", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 15835, municipio: "Turmequé", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15837, municipio: "Tuta", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 15839, municipio: "Tutazá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25839, municipio: "Ubalá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25841, municipio: "Ubaque", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 76845, municipio: "Ulloa", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15842, municipio: "Umbita", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25845, municipio: "Une", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 27800, municipio: "Unguía", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 27810, municipio: "Unión Panamericana", daneDep: 27, departamento: "Chocó" },
    { daneMuni: 5842, municipio: "Uramita", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 50370, municipio: "Uribe", daneDep: 50, departamento: "Meta" },
    { daneMuni: 44847, municipio: "Uribia", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 5847, municipio: "Urrao", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 44855, municipio: "Urumita", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 8849, municipio: "Usiacurí", daneDep: 8, departamento: "Atlántico" },
    { daneMuni: 25851, municipio: "Útica", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 5854, municipio: "Valdivia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 23855, municipio: "Valencia", daneDep: 23, departamento: "Córdoba" },
    { daneMuni: 86865, municipio: "Valle de Guamez", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 68855, municipio: "Valle de San José", daneDep: 68, departamento: "Santander" },
    { daneMuni: 73854, municipio: "Valle de San Juan", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 20001, municipio: "Valledupar", daneDep: 20, departamento: "Cesar" },
    { daneMuni: 5856, municipio: "Valparaíso", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 18860, municipio: "Valparaíso", daneDep: 18, departamento: "Caquetá" },
    { daneMuni: 5858, municipio: "Vegachí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 68861, municipio: "Vélez", daneDep: 68, departamento: "Santander" },
    { daneMuni: 73861, municipio: "Venadillo", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 5861, municipio: "Venecia", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 25506, municipio: "Venecia", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15861, municipio: "Ventaquemada", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25862, municipio: "Vergara", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 76863, municipio: "Versalles", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 68867, municipio: "Vetas", daneDep: 68, departamento: "Santander" },
    { daneMuni: 25867, municipio: "Vianí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 17867, municipio: "Victoria", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 5873, municipio: "Vigía del Fuerte", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 76869, municipio: "Vijes", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 54871, municipio: "Villa Caro", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 15407, municipio: "Villa de Leyva", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25843, municipio: "Villa de San Diego de Ubate", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 54874, municipio: "Villa del Rosario", daneDep: 54, departamento: "Norte de Santander" },
    { daneMuni: 19845, municipio: "Villa Rica", daneDep: 19, departamento: "Cauca" },
    { daneMuni: 86885, municipio: "Villagarzón", daneDep: 86, departamento: "Putumayo" },
    { daneMuni: 25871, municipio: "Villagómez", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 73870, municipio: "Villahermosa", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 17873, municipio: "Villamaría", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 13873, municipio: "Villanueva", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 44874, municipio: "Villanueva", daneDep: 44, departamento: "La Guajira" },
    { daneMuni: 68872, municipio: "Villanueva", daneDep: 68, departamento: "Santander" },
    { daneMuni: 85440, municipio: "Villanueva", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 25873, municipio: "Villapinzón", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 73873, municipio: "Villarrica", daneDep: 73, departamento: "Tolima" },
    { daneMuni: 50001, municipio: "Villavicencio", daneDep: 50, departamento: "Meta" },
    { daneMuni: 41872, municipio: "Villavieja", daneDep: 41, departamento: "Huila" },
    { daneMuni: 25875, municipio: "Villeta", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25878, municipio: "Viotá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 15879, municipio: "Viracachá", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 50711, municipio: "Vista Hermosa", daneDep: 50, departamento: "Meta" },
    { daneMuni: 17877, municipio: "Viterbo", daneDep: 17, departamento: "Caldas" },
    { daneMuni: 25885, municipio: "Yacopí", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 52885, municipio: "Yacuanquer", daneDep: 52, departamento: "Nariño" },
    { daneMuni: 41885, municipio: "Yaguará", daneDep: 41, departamento: "Huila" },
    { daneMuni: 5885, municipio: "Yalí", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5887, municipio: "Yarumal", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 97889, municipio: "Yavaraté", daneDep: 97, departamento: "Vaupés" },
    { daneMuni: 5890, municipio: "Yolombó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 5893, municipio: "Yondó", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 85001, municipio: "Yopal", daneDep: 85, departamento: "Casanare" },
    { daneMuni: 76890, municipio: "Yotoco", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 76892, municipio: "Yumbo", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 13894, municipio: "Zambrano", daneDep: 13, departamento: "Bolívar" },
    { daneMuni: 68895, municipio: "Zapatoca", daneDep: 68, departamento: "Santander" },
    { daneMuni: 47960, municipio: "Zapayán", daneDep: 47, departamento: "Magdalena" },
    { daneMuni: 5895, municipio: "Zaragoza", daneDep: 5, departamento: "Antioquia" },
    { daneMuni: 76895, municipio: "Zarzal", daneDep: 76, departamento: "Valle del Cauca" },
    { daneMuni: 15897, municipio: "Zetaquira", daneDep: 15, departamento: "Boyacá" },
    { daneMuni: 25898, municipio: "Zipacón", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 25899, municipio: "Zipaquirá", daneDep: 25, departamento: "Cundinamarca" },
    { daneMuni: 47980, municipio: "Zona Bananera", daneDep: 47, departamento: "Magdalena" },
];

export default municipios;